var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("div", { staticClass: "doctor" }, [
        _c("div", { staticClass: "header flex" }, [
          _c("img", { attrs: { src: _vm.doctor.avatar || _vm.defDoctor } }),
          _c("div", { staticClass: "info flex f-jc-c" }, [
            _c("h1", [_vm._v(_vm._s(_vm.doctor.name))]),
            _c("div", [
              _c("span", { staticClass: "d-name" }, [
                _vm._v(_vm._s(_vm.doctor.deptName)),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.doctor.titleName))]),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.doctor.hospitalName))]),
          ]),
        ]),
        _c("div", { staticClass: "middle flex f-it-c" }, [
          _c("h1", [_vm._v("100%")]),
          _c("span", { staticClass: "mr-20" }, [_vm._v("好评率")]),
          _c("h1", [_vm._v(_vm._s(_vm.doctor.consultationCount))]),
          _c("span", { staticClass: "mr-20" }, [_vm._v("接诊人数")]),
          _c("h1", [_vm._v("2小时内")]),
          _c("span", [_vm._v("响应")]),
        ]),
        _c("div", { staticClass: "bottom" }, [
          _c("div", { staticClass: "good-at" }, [
            _c("i", [_vm._v("擅长：")]),
            _c("div", { class: _vm.showAll ? "all" : "" }, [
              _vm._v(" " + _vm._s(_vm.doctor.goodAt) + " "),
            ]),
          ]),
          _c("div", { staticClass: "good-at" }, [
            _c("i", [_vm._v("简介：")]),
            _c("div", { class: _vm.showAll ? "all" : "" }, [
              _vm._v(" " + _vm._s(_vm.doctor.description || "无") + " "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "icon-cont", on: { click: _vm.toggleArrow } },
          [_c("i", { staticClass: "icon", class: _vm.showAll ? "to-top" : "" })]
        ),
      ]),
      _vm.showToCounsultation
        ? _c("div", { staticClass: "service-title flex f-ai-c" }, [
            _c("h1", [_vm._v("服务")]),
            _c("span", [_vm._v("医生48小时不回复将自动退款")]),
          ])
        : _vm._e(),
      _vm.showToCounsultation
        ? _c(
            "div",
            _vm._l(_vm.doctor.services, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex f-ai-c service-item" },
                [
                  item.type === 1
                    ? [
                        item.subType === 1
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/images/service-1.png"),
                                },
                              }),
                              _c("div", { staticClass: "flex" }, [
                                _c("h1", [_vm._v("图文问诊")]),
                                _c("span", { staticClass: "price1" }, [
                                  _vm._v("￥" + _vm._s(item.price) + "/48小时"),
                                ]),
                                _c("i", [_vm._v("48小时不限沟通次数")]),
                              ]),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.toSought("1-1")
                                    },
                                  },
                                },
                                [_vm._v("去咨询")]
                              ),
                            ]
                          : _vm._e(),
                        item.subType === 2
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/images/service-2.png"),
                                },
                              }),
                              _c("div", { staticClass: "flex" }, [
                                _c("h1", [_vm._v("图文问诊")]),
                                _c("span", { staticClass: "price2" }, [
                                  _vm._v(
                                    "￥" + _vm._s(item.price) + "/3次对话"
                                  ),
                                ]),
                                _c("i", [_vm._v("一问一答，适合简单问题")]),
                              ]),
                              _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.toSought("1-2")
                                    },
                                  },
                                },
                                [_vm._v("去咨询")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "service-title flex f-ai-c pr" }, [
        _c("h1", [_vm._v("患者评价")]),
        _c("i", [_vm._v("(" + _vm._s(_vm.appCount || 0) + ")")]),
        _c("em", { staticClass: "good-icon" }, [_vm._v(" 100%")]),
      ]),
      _vm.appraises.length
        ? _c(
            "ul",
            { staticClass: "appraise" },
            [
              _vm._l(_vm.appraises, function (item, index) {
                return _c("li", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "top flex f-ai-c" },
                    [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("van-rate", {
                        attrs: {
                          readonly: "",
                          size: 20,
                          color: "#ffd21e",
                          "void-icon": "star",
                          "void-color": "#eee",
                        },
                        model: {
                          value: item.star,
                          callback: function ($$v) {
                            _vm.$set(item, "star", $$v)
                          },
                          expression: "item.star",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "appr" }, [
                    _c("span", [_vm._v(_vm._s(item.content))]),
                  ]),
                  _c("div", { staticClass: "footer flex f-jc-sb" }, [
                    _c("span", [
                      _vm._v(
                        "[" +
                          _vm._s(item.serviceType === 1 ? "图文问诊" : "") +
                          "]"
                      ),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.createdAt))]),
                  ]),
                ])
              }),
              !_vm.showAllAppFlag && _vm.appCount > 2
                ? _c("div", { staticClass: "all-c" }, [
                    _c(
                      "div",
                      { staticClass: "app-all", on: { click: _vm.showAllApp } },
                      [_vm._v("查看全部评价")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticStyle: { "background-color": "#F3F3F3" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPatients,
            callback: function ($$v) {
              _vm.showPatients = $$v
            },
            expression: "showPatients",
          },
        },
        [
          _c(
            "ul",
            { staticClass: "patients" },
            [
              _vm._l(_vm.patients, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "flex f-jc-sb f-ai-c",
                    on: {
                      click: function ($event) {
                        return _vm.checkPatient(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "left flex f-ai-c" }, [
                      _c("img", {
                        attrs: { src: item.gender === 1 ? _vm.man : _vm.woman },
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _c("i", { staticClass: "right" }),
                  ]
                )
              }),
              _vm.patients.length < 5
                ? _c(
                    "li",
                    {
                      staticClass: "flex f-jc-sb f-ai-c blue",
                      on: { click: _vm.toAddPatient },
                    },
                    [
                      _c("div", { staticClass: "left flex f-ai-c" }, [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/plus-blue.png"),
                          },
                        }),
                        _c("span", [_vm._v("添加就诊人")]),
                      ]),
                      _c("i", { staticClass: "right right-blue" }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "cancel", on: { click: _vm.hidePatients } },
            [_vm._v("取消")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }