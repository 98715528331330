<template>
	<div class="wrap">
		<div class="doctor">
			<div class="header flex">
				<img :src="doctor.avatar || defDoctor">
				<div class="info flex f-jc-c">
					<h1>{{doctor.name}}</h1>
					<div>
						<span class="d-name">{{doctor.deptName}}</span>
						<span>{{doctor.titleName}}</span>
					</div>
					<span>{{doctor.hospitalName}}</span>
				</div>
			</div>
			<div class="middle flex f-it-c">
				<h1>100%</h1>
				<span class="mr-20">好评率</span>
				<h1>{{doctor.consultationCount}}</h1>
				<span class="mr-20">接诊人数</span>
				<h1>2小时内</h1>
				<span>响应</span>
			</div>
			<div class="bottom">
				<div class="good-at">
					<i>擅长：</i>
					<div :class="showAll ? 'all' : ''">
						{{doctor.goodAt}}
					</div>
				</div>
				<div class="good-at">
					<i>简介：</i>
					<div :class="showAll ? 'all' : ''">
						{{doctor.description || '无'}}
					</div>
				</div>
			</div>
			<div class="icon-cont" @click="toggleArrow">
				<i class="icon" :class="showAll ? 'to-top' : ''"></i>
			</div>
		</div>
		<div v-if="showToCounsultation" class="service-title flex f-ai-c">
			<h1>服务</h1>
			<span>医生48小时不回复将自动退款</span>
		</div>
		<div v-if="showToCounsultation">
			<div v-for="(item, index) in doctor.services" :key="index" class="flex f-ai-c service-item">
				<template v-if="item.type === 1">
					<template v-if="item.subType === 1">
						<img src="../../assets/images/service-1.png">
						<div class="flex">
							<h1>图文问诊</h1>
							<span class="price1">￥{{item.price}}/48小时</span>
							<i>48小时不限沟通次数</i>
						</div>
						<button @click="toSought('1-1')">去咨询</button>
					</template>
					<template v-if="item.subType === 2">
						<img src="../../assets/images/service-2.png">
						<div class="flex">
							<h1>图文问诊</h1>
							<span class="price2">￥{{item.price}}/3次对话</span>
							<i>一问一答，适合简单问题</i>
						</div>
						<button @click="toSought('1-2')">去咨询</button>
					</template>
				</template>
			</div>
		</div>
		<div class="service-title flex f-ai-c pr">
			<h1>患者评价</h1>
			<i>({{appCount || 0}})</i>
			<em class="good-icon"> 100%</em>
		</div>
		<ul class="appraise" v-if="appraises.length">
			<li v-for="(item, index) in appraises" :key="index">
				<div class="top flex f-ai-c">
					<span class="name">{{item.name}}</span>
					<van-rate readonly v-model="item.star" :size="20" color="#ffd21e" void-icon="star" void-color="#eee" />
				</div>
				<div class="appr">
					<span>{{item.content}}</span>
				</div>
				<div class="footer flex f-jc-sb">
					<span>[{{item.serviceType === 1 ? '图文问诊' : ''}}]</span>
					<span>{{item.createdAt}}</span>
				</div>
			</li>
			<!-- 超过2条评价时，才展示此按钮 -->
			<div class="all-c" v-if="!showAllAppFlag && appCount > 2">
				<div class="app-all" @click="showAllApp">查看全部评价</div>
			</div>
		</ul>
		<van-popup v-model="showPatients" position="bottom" style="background-color: #F3F3F3;">
			<ul class="patients">
				<li class="flex f-jc-sb f-ai-c" v-for="(item, index) in patients" :key="index" @click="checkPatient(item.id)">
					<div class="left flex f-ai-c">
						<img :src="item.gender === 1 ? man : woman">
						<span>{{item.name}}</span>
					</div>
					<i class="right"></i>
				</li>
				<li v-if="patients.length < 5" class="flex f-jc-sb f-ai-c blue" @click="toAddPatient">
					<div class="left flex f-ai-c">
						<img src="../../assets/images/plus-blue.png">
						<span>添加就诊人</span>
					</div>
					<i class="right right-blue"></i>
				</li>
			</ul>
			<div class="cancel" @click="hidePatients">取消</div>
		</van-popup>
	</div>
</template>
<script>
import { doctorDetail, doctorAppraise, doctorDetailBind } from '@/request/api/doctor';
import { consultationByPatientAndDoctor, patientList, consultationCanAdd } from '@/request/api/user';
export default {
	name: 'doctorDetail',
	data () {
		return {
			doctor: {},
			defDoctor: require('@/assets/images/doctor-def.png'),
			woman: require('@/assets/images/patient-w.png'),
			man: require('@/assets/images/patient-m.png'),
			showAll: false,
			appraises: [],
			appCount: 0,
			showAllAppFlag: false,
			showPatients: false,
			showToCounsultation: true,
			soughtType: '' // 服务类型
		};
	},
	created () {
		if (this.$route.path === '/doctor-detail-ruiantang') {
			this.doctorDetailNew();
		} else {
			this.doctorDetail();
		}
		if (this.$route.path === '/dip') {
			this.showToCounsultation = false;
		} else {
			this.showToCounsultation = true;
		}
		this.mainGetPatients();
		this.doctorAppraise();
	},
	computed: {
		patients: function () {
			return this.$store.state.main.patients;
		}
	},
	mounted () {
		// console.log('this=route====', this.$route)
		if (this.$route.path === '/doctor-detail') {
			if (window.history && window.history.pushState) {
				history.pushState(null, null, document.URL);
				window.addEventListener('popstate', this.onBack, false);
			}
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.$root.go('/');
		},
		setTitle () {
			document.title = `${this.doctor.name} ${this.doctor.deptName}`;
		},
		mainGetPatients () {
			patientList().then(data => {
				if (data && data === 'retry') {
					this.mainGetPatients();
				} else if (data) {
					this.$store.commit('setPatients', data);
				}
			}).catch(e => {
				this.$root.elMsg(e.message);
			});
		},
		// 扫码建立医患关系-查询医生详情
		doctorDetailNew () {
			doctorDetailBind({ id: this.$route.query.doctorId }).then(data => {
				if (data && data === 'retry') {
					this.doctorDetailNew();
				} else if (data) {
					this.doctor = data;
					this.setTitle();
				} else {
					this.doctor = {};
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		doctorDetail () {
			doctorDetail({ id: this.$route.query.doctorId }).then(data => {
				if (data && data === 'retry') {
					this.doctorDetail();
				} else if (data) {
					this.doctor = data;
					this.setTitle();
				} else {
					this.doctor = {};
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		doctorAppraise (type) {
			doctorAppraise({
				id: this.$route.query.doctorId,
				type: type ? 1 : ''
			}).then(data => {
				if (data && data === 'retry') {
					this.doctorAppraise();
				} else if (data) {
					this.appraises = data.appraises;
					this.appCount = data.count;
				} else {
					this.appraises = {};
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		showAllApp () {
			this.doctorAppraise(1);
			this.showAllAppFlag = true;
		},
		toggleArrow () {
			this.showAll = !this.showAll;
		},
		toSought (type) {
			// 判断是否可以给当前医生下单
			/* const user = JSON.parse(window.sessionStorage.getItem('userInfo'));
			consultationCanAdd({
				userId: user.id,
				doctorId: +this.$route.query.doctorId
			}).then(data => {
				if (data && data.ok === 1) {
					this.soughtType = type;
					if (this.$route.name === 'dip') {
						this.checkPatient(this.$route.query.patientId, 1);
					} else {
						this.showPatients = true;
					}
				}
			}); */
			this.soughtType = type;
			if (this.$route.name === 'dip') {
				this.checkPatient(this.$route.query.patientId, 1);
			} else {
				this.showPatients = true;
			}
		},
		hidePatients () {
			this.showPatients = false;
		},
		toAddPatient () {
			this.$root.go('/add-patient');
		},
		checkPatient (id, pageType) {
			this.$store.commit('setStep', {
				step: 'stepOne',
				obj: {}
			});
			consultationByPatientAndDoctor({ patientId: +id, doctorId: +this.$route.query.doctorId }).then(data => {
				if (data && data === 'retry') {
					this.checkPatient(id);
				} else if (data && data.consultation) {
					// console.log('data.consultation====', data.consultation);
					if (data.consultation.status === 1) {
						let qa = {};
						if (data.consultation.consultationAnswer) {
							qa = JSON.parse(data.consultation.consultationAnswer);
						}
						this.$store.commit('setStep', {
							step: 'stepTwo',
							obj: { qa: qa, consultationType: data.consultation.consultationType }
						});
						this.$store.commit('setStep', {
							step: 'stepConsultation',
							obj: { consultation: data.consultation }
						});
						this.$root.go('/consultation-pay', { doctorName: this.doctor.name, departmentName: this.doctor.deptName, departmentId: this.doctor.departmentId, subType: this.soughtType, patientId: id, doctorId: this.$route.query.doctorId });
					} else if (data.consultation.status === 2) {
						if (this.$route.name === 'dip') {
							this.$emit('refresh');
						} else {
							this.$root.go('/dip',
								{
									doctorId: +this.$route.query.doctorId,
									at: 2,
									patientId: data.consultation.patientId,
									conversationId: data.consultation.conversationId
								}
							);
						}
					}
				} else {
					if (pageType) {
						this.showPatients = true;
					} else {
						this.$root.go('/consultation-one', { doctorName: this.doctor.name, departmentName: this.doctor.deptName, departmentId: this.doctor.departmentId, subType: this.soughtType, patientId: id, doctorId: this.$route.query.doctorId });
					}
				}
			});
		}
	}
};
</script>
<style lang="less">
	.van-overlay {
		z-index: 999;
		background-color: rgba(0,0,0,.7);
	}
</style>
<style lang="less" scoped>
	.doctor {
		background-color: white;
		padding: 15px 25px 0 15px;
		.header {
			img {
				width: 70px;
				height: 70px;
				border-radius: 50%;
				margin-right: 10px;
			}
			.info {
				flex-direction: column;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #8B8B8B;
				h1 {
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #232830;
				}
				.d-name {
					margin-right: 11px;
					position: relative;
					&::after {
						content: '';
						position: absolute;
						display: inline-block;
						width: 1.5px;
						height: 5px;
						background: #EAEAEA;
						right: -6px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		.middle {
			margin-top: 17px;
			height: 25px;
			line-height: 25px;
			white-space: nowrap;
			h1 {
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #232830;
			}
			span {
				font-size: 11px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #959595;
				margin-left: 3px;
			}
			.mr-20 {
				margin-right: 16px;
			}
		}
		.bottom {
		}
		.icon-cont {
			height: 40px;
			position: relative;
			.icon {
				background: url('../../assets/images/bottom-arrow.png') no-repeat;
				background-size: contain;
				width: 20px;
				height: 20px;
				position: absolute;
				bottom: 10px;
				left: 50%;
				transform: translateX(-50%);
			}
			.to-top {
				transform: translateX(-50%) rotate(180deg);
			}
		}
	}
	.service-title {
		margin: 20px 0 15px 0;
		h1 {
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
			margin-right: 6px;
		}
		span {
			font-size: 12px;
			color: #959595;
		}
		i {
			font-size: 13px;
		}
		.good-icon {
			position: absolute;
			width: 11.5px;
			height: 11.5px;
			background: url('../../assets/images/good.png') no-repeat;
			background-size: contain;
			font-style: normal;
			color: #FF8D16;
			font-size: 11px;
			right: 0;
			width: 43px;
			text-align: right;
			height: 12px;
			display: inline-block;
			line-height: 13px;
			padding-left: 3px;
		}
	}
	.service-item {
		background-color: #FFFFFF;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 4px;
		padding: 15px;
		margin-bottom: 10px;
		img {
			width: 45px;
			height: 45px;
			margin-right: 5px;
		}
		div {
			flex: 1;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			flex-direction: column;
			h1 {
				font-size: 17px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #232830;
				margin-bottom: 10px;
			}
			span {
				font-weight: 400;
			}
			.price1 {
				font-size: 12px;
				font-weight: 400;
				color: #FF6450;
				line-height: 12px;
			}
			.price2 {
				font-size: 12px;
				font-weight: 400;
				line-height: 12px;
				color: #FF8D16;
			}
			i {
				font-size: 11px;
				color: #959595;
			}
		}
		button {
			width: 66px;
			height: 29px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 16.5px;
			border: none;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
		}
	}
	.appraise {
		padding: 25px 15px 20px;
		background: #FFFFFF;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 4px;
		margin-bottom: 20px;
		li {
			border-bottom: 1px solid #F3F3F3;
			padding-bottom: 15px;
			.top {
				margin-bottom: 12.5px;
				font-weight: 400;
				font-family: PingFangSC-Regular, PingFang SC;
				.name {
					font-size: 12px;
					color: #8C8C8C;
					margin-right: 10px;
				}
			}
			.appr {
				font-size: 15px;
				font-family: PingFangHK-Regular, PingFangHK;
				color: #232830;
				width: 100%;
				display: flex;
				span {
					word-break: break-word;
					white-space: pre-wrap;
					width: 100%;
				}
			}
			.footer {
				font-size: 12px;
				color: #999999;
				margin-top: 10px;
			}
		}
		.all-c {
			height: 30px;
			padding-top: 15px;
			background: white;
			margin-top: -2px;
			.app-all {
				width: 104px;
				height: 25px;
				border-radius: 14px;
				border: 1px solid #BABABA;
				text-align: center;
				line-height: 25px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #7D7D7D;
				margin: 0 auto;
			}
		}
	}
	.good-at {
		margin-top: 20px;
		position: relative;
		font-size: 12px;
		i {
			position: absolute;
			color: #232830;
			font-weight: 600;
		}
		div {
			text-indent: 50px;
			color: #9396A0;
			word-break: break-all;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.all {
			overflow: auto !important;
			display: block !important;
		}
	}
	.patients {
		padding: 25px 15px 22px;
		background-color: #FFFFFF;
		width: 100%;
		box-sizing: border-box;
		li {
			// width: 345px;
			height: 55px;
			background: #F3F3F3;
			border-radius: 4px;
			opacity: 0.79;
			padding: 9px 12px;
			margin-bottom: 10px;
			box-sizing: border-box;
			&.blue {
				background: #F5F9FF;
				border: 1px solid #ACD1FF;
				span {
					color: #FF6450;
				}
			}
			.left {
				font-size: 15px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #3B3D40;
				img {
					width: 37px;
					height: 37px;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
			.right {
				width: 8px;
				height: 14px;
				background: url('../../assets/images/right-arrow.png') no-repeat;
				background-size: contain;
			}
			.right-blue {
				background: url('../../assets/images/right-arrow-blue.png') no-repeat;
				background-size: contain;
			}
		}
	}
	.cancel {
		height: 52px;
		background: #FFFFFF;
		text-align: center;
		line-height: 52px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #3B3D40;
		margin-top: 10px;
	}
</style>